import React, { useEffect } from "react";
import { Link, graphql } from "gatsby";
import ArrowButton from "../../components/ArrowButton";

import RectangleImg from "../../images/rectangle.svg";
import SkewRectangleHeroImg from "../../images/skewRectangleHero.png";
import { Seo } from "../../components/SEO/Seo.jsx";
import CTABanner from "../../components/CTABanner/CTABanner";

import MainMenu from "../../components/MainMenu/MainMenu";
import Footer from "../../components/Footer/Footer.jsx";

import AOS from "aos";
import "aos/dist/aos.css";

const PortfolioList = ({ data }) => {
  const portfolioList = data.allMdx.nodes.filter(
    node => node.fields.source === "portfolio"
  );

  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);

  return (
    <>
      <Seo
        title="Portfolio | GammaSoft: Custom Software Development Company"
        description="Check out our projects"
      />
      <MainMenu />
      <div
        className="portfolio-hero"
        style={{ backgroundImage: `url(${SkewRectangleHeroImg})` }}
      >
        <div className="portfolio-hero-content container">
          <div className="portfolio-hero-content-left" data-aos="fade-up">
            <h1 className="heading-primary">
              Here is a sample of the work we have done
            </h1>
            <p className="paragraph-primary">
              Are you interested in learning more about the projects we are most
              proud of? Over the years, we have completed a range of successful
              projects, including mobile, web, and desktop applications and
              solutions that help our clients overcome their business
              challenges.
            </p>
            <p className="paragraph-primary">
              Contact us today to learn more about our work and how we can
              support your business.
            </p>
          </div>
          <div className="portfolio-hero-content-right" data-aos="fade-left">
            <img src={RectangleImg} alt="" />
          </div>
        </div>
      </div>
      <section className="projects-list">
        <div className="projects-list-content container">
          {portfolioList.map(portfolioItem => {
            return (
              <div
                className="projects-list-content-item"
                data-aos="fade-up"
                data-aos-delay={50}
              >
                <img src={portfolioItem.frontmatter.thumb.publicURL} alt="" />
                <h3 className="heading-secondary">
                  {portfolioItem.frontmatter.portfolioTitle}
                </h3>
                <p className="paragraph-primary">
                  {portfolioItem.frontmatter.paragraphPrimary}
                </p>
                <div className="projects-list-btn">
                  <Link
                    to={"/portfolio/" + portfolioItem.frontmatter.slug}
                    key={portfolioItem.id}
                    className="btn btn-secondary"
                  >
                    <ArrowButton text="Read more" />
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </section>
      <CTABanner />
      <Footer />
    </>
  );
};

export default PortfolioList;

export const query = graphql`
  query PortfolioPage {
    allMdx {
      nodes {
        fields {
          source
        }
        frontmatter {
          slug
          portfolioTitle
          paragraphPrimary
          thumb {
            publicURL
          }
        }
        id
      }
    }
  }
`;
